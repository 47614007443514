.Container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  background: rgba(0, 0, 0, 0.5);
}

@media (max-height: 550px) {
  .Container {
    /* top: 55px; */
  }
}

/* .Container::-webkit-scrollbar {
    width: 2px;
}

.Container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.Container::-webkit-scrollbar-track {
    background: #fff;
} */

.Modal {
  position: absolute;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 0px solid #ccc;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  /* border-radius: 4px; */
  /* padding: 4px; */
  /* padding: 30px 40px; */
  /* left: 15%; */
  /* top: 5%; */
  /* margin-bottom: 5%; */
  margin: 0 auto;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.Modal .CloseBtn {
  cursor: pointer;
  opacity: 0.2;
  padding: 0;
  margin-left: 1rem;
  display: block;
  float: right;
  position: relative;
  bottom: 2px;
  background: transparent;
  border: 0;
  outline: none;
  z-index: 100;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
}

.CloseBtn:hover {
  color: black;
  opacity: 0.6;
}

.Content {
  min-height: 80px;
  padding-top: 20px;
  font-size: 18px;
  font-weight: 500;
}

@media (min-width: 600px) {
  .Modal {
    width: 700px;
    /* left: calc(50% - 350px); */
    /* top: 5%; */
  }
}

@media (max-width: 600px) {
  .Modal .CloseBtn {
    background: #ffffff40;
  }

  .Modal {
    left: 0 !important;
    width: 100% !important;
    top: 0;
    margin-bottom: 0px;
  }
}

.Container.Hidden {
  /* display: none; */
  transform: translateY(-100vh);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  height: 0px;
}

.Container.Hidden .Modal {
  transform: translateY(-100vh);
  opacity: 0;
}

.HeaderContainer,
.Footer,
.Body {
  padding: 1.5rem;
  width: 100%;
}

.HeaderContainer {
  border-bottom: 1px solid #e5e5e5;
  padding-right: 4rem;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.Header {
  width: 100%;
}

.Footer {
  border-top: 1px solid #e5e5e5;
}


.ModalActions {
  display: flex;
  justify-content: flex-end;
}

.btnSucceed {
   box-shadow: none;
  background: #009ae0;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  padding:1rem;
  border-radius:4px;
}

.btnPrevent {
 box-shadow: none;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  color: #009ae0;
  padding:1rem;
  border-radius:4px;
  border:1px solid #009ae0;
}