.VideoPanel {
  display: flex;
  height: 100%;
}
.mainVideo {
  display: flex;
  width: 100%;
  height: 100%;
  background: #1e272e;
}

.screenShareDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.screenShareDiv > div {
  display: none;
}

.showHideButtonContainer {
  position: relative;
}


.sidevideoPanel {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  width: 90%;
  margin:0 auto;
  left: 10px;
}
.sidevideo,
.sidevideoPanel div {
  display: flex;
  width: 90%;
  position: relative;
  height: 25vh !important;
  background: transparent;
  border: 1px solid black;
  margin: 1rem 0;
}

.presentingNow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
}

.connectionState {
  position: absolute;
  z-index: 1000;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* 
.ripple {
  margin: auto;
  margin-top: 5rem;
  background-color: #fff;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position:relative;
  animation: rippleScreenShare 3s linear infinite;
  
}
.ripple::before,
.ripple::after{
  content:"";
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius: 50%;
  animation: rippleScreenShare 3s linear infinite 1s;
}
.ripple::after {
  animation: rippleShare 3s linear infinite 2s;
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 .7rem rgba(255,255,255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 8rem rgba(255,255,255, 0);
  }
} */

.rippleContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  top: 50%;
}

.rippleContainer span:nth-child(1) {
  animation-delay: 0.8s;
}

.rippleContainer span:nth-child(2) {
  animation-delay: 0.8s;
}

.rippleContainer span {
  position: absolute;
  opacity: 1;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 100px;
  display: inline-block;
  animation: rippleScreenShare 3s linear infinite 1s;
  transform: scale(0);
}

@keyframes rippleScreenShare {
  to {
    opacity: 0;
    transform: scale(4);
  }
}

.centered {
  text-align: center;
}

.spinner.loading {
  display: block;
  padding: 50px;
  text-align: center;
}

.spinner.loading:before {
  content: "";
  height: 90px;
  width: 90px;
  margin: -15px auto auto -15px;
  position: absolute;
  top: calc(50% - 45px);
  border-width: 8px;
  border-style: solid;
  border-color: #2180c0 #ccc #ccc;
  border-radius: 100%;
  animation: rotation 0.7s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
