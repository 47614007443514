.ChatDropdown > .Btn {
    padding: 5px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}
.ChatDropdown > .Btn > p {
    font-weight: bold;
    padding: 2px;
    float: right;
    margin-left: 5px;
    transform: rotate(0deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.ChatDropdown > .Btn > p.Open {
    transform: rotate(180deg);
}

.ChatDropdown {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    /*width: 100%;*/
    /* background-color: #fafafa; */
}

.ChatDropdown ul {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;
    height: inherit;
}

.ChatDropdown li {
    height: inherit;
}

@keyframes slideup {
    from {
        transform: translateY(5%);
    }
    to {
        transform: translateY(0);
    }
}

.ChatDropdown .Items {
    position: absolute;
    left: 0;
    /* width: 100%; */
    min-width: max-content;
    top: 100%;
    /* max-height: 150px; */
    background-color: #fcfcfc;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    animation: slideup 0.3s, hs-fadeIn 0.3s;
    opacity: 1;
    z-index: 1;
    max-height: 250px;
    overflow-y: auto;
}

.Items.Hide {
    /* max-height: 0px; */
    opacity: 0;
    -webkit-transition: max-height 0.3s ease;
    -moz-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease;
    display: none;
}

/* .Items.Hide li {
  margin: 0px;
  -webkit-transition: margin 0.6s ease;
  -moz-transition: margin 0.6s ease;
  transition: margin 0.6s ease;
}

.Items li {
  -webkit-transition: margin 0.6s ease;
  -moz-transition: margin 0.6s ease;
  transition: margin 0.6s ease;
  margin: 0px;
}

.Items li>a {
  -webkit-transition: max-height 0.3s ease;
  -moz-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  -webkit-transition: padding 0.3s ease;
  -moz-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
  -webkit-transition: border 0.3s ease;
  -moz-transition: border 0.3s ease;
  transition: border 0.3s ease;
}

.Items.Hide li>a {
  max-height: 0px;
  padding: 0px;
  border: 0px;
  -webkit-transition: max-height 0.3s ease;
  -moz-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  -webkit-transition: padding 0.3s ease;
  -moz-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
  -webkit-transition: border 0.3s ease;
  -moz-transition: border 0.3s ease;
  transition: border 0.3s ease;
} */

.ChatDropdown .Btn {
    display: flex;
    align-items: center;
    width: 100%;
   
    /* padding: 10px;
    */
    margin: 0px;
    font-family: "Noto Sans", sans-serif;
    background-color: transparent;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
}

.ChatDropdown .Btn > p {
    font-size: 24px;
    color: black;
    margin-bottom: 5px;
    margin-top: 0px;
}

.ChatDropdown ul {
    background: #fff;
    min-width: fit-content;
    /*border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;*/
    border-radius: 4px;
    /*border: 1px solid #ccc;*/
}

.ChatDropdown ul li {
    /*margin: 5px 0px;*/
    padding: 10px 22px;
    margin-top: -1px;
    width: 100%;
    min-width: fit-content;
    color: #333;
    font-weight: 400;
    box-sizing: border-box;
    /*border: 1px solid #ccc;*/
    /*border-bottom: 1px solid #ccc;*/
}

.ChatDropdown ul li:hover {
    background: #f5f5f5;
    cursor: pointer;
}

/* .ChatDropdown li > a {
    padding-top: 6px;
    padding-bottom: 6px;
    text-decoration: none;
    color: black;
} */

.Caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top-width: 4px;
    border-top-style: dashed;
    border-top-color: initial;
    border-right: 4px solid transparent;
    border-right-width: 4px;
    border-right-style: solid;
    border-right-color: transparent;
    border-left: 4px solid transparent;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: transparent;
}

/* if want to hide whole Chatdropdown then use 'Hidden' global class */
:global(.Hidden .ChatDropdown){
    display: none
}

:global(.Hidden .ChatDropdownOpen){
    display: block;
}